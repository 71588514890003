<template>
  <CommonLayout v-if="quests.length">
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <div class="cabinet__row mb-50">
            <div class="cabinet__col">
              <h1 class="title">Командные цели</h1>
            </div>
          </div>
          <div class="cabinet__row">
            <div class="cabinet__col">
              <div class="goal-head">
                <div class="goal-head__row">
                  <div class="goal-head__group">
                    <div class="goal-head__group-name">Ваша цель</div>
                    <div class="goal-head__group-title">
                      {{ quest.title }}
                    </div>
                  </div>
                </div>
                <div class="goal-head__row">
                  <div class="goal-head__col">
                    <div class="goal-head__title">
                      Статус цели<Tooltip
                        class="goal-head__tooltip"
                        :msg="`<table>
                                <tr><td>Назначена</td><td>подготовьтесь к испытанию</td></tr>
                                <tr><td>В работе</td><td>время постараться!</td></tr>
                                <tr><td>На модерации</td><td>подождите немного</td></tr>
                                <tr><td>Выполнена</td><td>поздравляем, награды ваши</td></tr>
                                <tr><td>НЕ ВЫПОЛНЕНА</td><td>ничего, получится в другой раз</td></tr>
                              </table>`"
                      />
                    </div>
                    <div class="goal-head__subtitle">
                      {{ quest.state | formatTeamQuest }}
                    </div>
                  </div>
                  <div class="goal-head__col">
                    <div class="goal-head__text">
                      Дата начала: {{ quest.startDate | formatDate2 }}<br />Дата
                      окончания: {{ quest.endDate | formatDate2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cabinet__col">
              <div class="goal-legend">
                <div class="goal-legend__inner">
                  <div class="goal-legend__head">
                    <Tooltip
                      v-if="quest.hint"
                      class="goal-legend__tooltip"
                      :msg="quest.hint"
                    />
                    <div class="goal-legend__title">Цели</div>
                  </div>
                  <div class="goal-legend__body">
                    <table>
                      <thead>
                        <tr>
                          <th>Результат:</th>
                          <th>Награды</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in quest.targets" :key="index">
                          <td
                            :class="{
                              green: item.targetScore === 5,
                              yellow: item.targetScore === 4,
                              pink: item.targetScore === 3,
                              red: item.targetScore === 2,
                            }"
                          >
                            <div class="overflow" :title="item.value">
                              {{ item.value }}
                            </div>
                          </td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />{{ item.coins | priceFilter }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="green">0,3</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />2 500
                          </td>
                        </tr>
                        <tr>
                          <td class="yellow">0,5</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />1 500
                          </td>
                        </tr>
                        <tr>
                          <td class="pink">0,70</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />500
                          </td>
                        </tr>
                        <tr>
                          <td class="red">0,70</td>
                          <td>
                            <img
                              src="@/assets/img/pic2/coin.svg"
                              width="15"
                              height="15"
                              alt=""
                            />0
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cabinet__body">
          <div v-if="loading" class="goal-skeleton">
            <Loading position="center" />
          </div>
          <div v-else class="goal">
            <div class="goal__head">
              <div class="goal__col">
                <table class="goal-table">
                  <thead>
                    <tr>
                      <th>Участник команды</th>
                      <th>Результат</th>
                      <th>Получено</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="goal__col">
                <table class="goal-table">
                  <thead>
                    <tr>
                      <th>Участник команды</th>
                      <th>Результат</th>
                      <th>Получено</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div class="goal__body">
              <div class="goal__col">
                <table class="goal-table">
                  <tbody>
                    <TeamGoalPlayerTableItem
                      v-for="(player, index) in playersEven"
                      :key="index"
                      :player="player"
                    />
                  </tbody>
                </table>
              </div>
              <div class="goal__col">
                <table class="goal-table">
                  <tbody>
                    <TeamGoalPlayerTableItem
                      v-for="(player, index) in playersOdd"
                      :key="index"
                      :player="player"
                    />
                  </tbody>
                </table>
              </div>
            </div>
            <div class="goal-foot">
              <div v-if="quest.state <= 2" class="goal-foot__title">
                Промежуточный результат команды:
              </div>
              <div v-else class="goal-foot__title big">Результат команды:</div>
              <div class="goal-foot__list">
                <div class="goal-foot__item">
                  <span>Результат</span>
                  <strong
                    :class="{
                      green: quest.finalGrade === 5,
                      yellow: quest.finalGrade === 4,
                      pink: quest.finalGrade === 3,
                      red: quest.finalGrade === 2,
                    }"
                    >{{
                      quest.finalScore !== null ? quest.finalScore : "-"
                    }}</strong
                  >
                </div>
                <div class="goal-foot__item">
                  <span>Награда</span>
                  <strong
                    ><img
                      src="@/assets/img/pic2/coin.svg"
                      width="25"
                      height="25"
                      alt=""
                    />{{
                      quest.finalCoins !== null ? quest.finalCoins : "-"
                    }}</strong
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
  <CabinetContentLayout
    v-else
    :has-button-back="true"
    :is-loading="loading"
    title="Командные цели"
  >
    <template #default>
      <p>
        В разделе «Командная цель» вам вместе с командой нужно будет <br />
        выполнять общую задачу и достигать командного результата. <br />
        Вклад каждого участника влияет на результат команды и возможность <br />
        получить награду.
      </p>
      <p>Следите за уведомлениями, чтобы узнать о назначении новой цели!</p>
    </template>
    <template #foot>
      <div>
        <button
          type="button"
          class="button button-link button_big mt-20"
          @click="$modal.show('teamGoalsHistory')"
        >
          <span>Посмотреть историю</span>
        </button>
      </div>
    </template>
  </CabinetContentLayout>
</template>

<script>
import { GetTeamQuestHistory } from "@/api/TeamQuest";
import Loading from "@/components/Loading.vue";
import TeamGoalPlayerTableItem from "@/components/TeamGoalPlayerTableItem.vue";
import Tooltip from "@/components/Tooltip.vue";
import CabinetContentLayout from "@/layout/CabinetContentLayout.vue";
import CommonLayout from "@/layout/CommonLayout.vue";
import { declOfNum } from "@/utils";

export default {
  components: {
    CommonLayout,
    Tooltip,
    Loading,
    CabinetContentLayout,
    TeamGoalPlayerTableItem,
  },
  computed: {
    questsAmount() {
      return declOfNum(this.quests.length, [
        "командная цель",
        "командных цели",
        "командных целей",
      ]);
    },
    playersOdd() {
      return this.quest.playerStates?.filter((e, i) => i % 2);
    },
    playersEven() {
      return this.quest.playerStates?.filter((e, i) => !(i % 2));
    },
  },
  data() {
    return {
      loading: false,
      quests: [],
      quest: {},
    };
  },
  async created() {
    const { id } = this.$route.params;

    await this.fetchData();

    if (id) {
      this.getQuest(id);
    }
  },
  watch: {
    $route(to) {
      const { id } = to.params;

      if (id) {
        this.getQuest(id);
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { data } = await GetTeamQuestHistory();

        this.quests = data;
      } catch (error) {
        new Error(error);
      } finally {
        this.loading = false;
      }
    },
    getQuest(id) {
      this.quest = this.quests.find((e) => e.id === +id) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  width: 100%;
  margin-top: 30px;
  &__head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    // overflow: auto;
    // @include scrollbar;
    // height: calc(100vh - 550px);
    // min-height: 280px;
  }
  &__history-button {
    @include reset-button;
    display: inline-block;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #50ffeb;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.goal-skeleton {
  width: 100%;
}
.goal-legend {
  background-image: url(~@/assets/img/pics/legend-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 322px;
  height: 226px;
  &__inner {
    padding: 23px 55px;
  }
  &__head {
    position: relative;
  }
  &__tooltip {
    position: absolute;
    top: 8px;
    right: 100%;
  }
  &__title {
    font-size: 24px;
    color: #50ffeb;
  }
  &__body {
    table {
      width: 100%;
      text-align: left;
      border-spacing: 0px;
      thead {
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            color: #ade5d9;
          }
        }
      }
      tbody {
        font-weight: 700;
        font-size: 19px;
        tr {
          td {
            white-space: nowrap;
            .overflow {
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-right: 5px;
            }
            img {
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.goal-table {
  text-align: left;
  width: 100%;
  border-spacing: 0;

  &::v-deep {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: calc(100% - 240px);
        }
        &:nth-child(2) {
          width: 120px;
          text-align: center;
          white-space: nowrap;
          > div {
            justify-content: center;
          }
        }
        &:nth-child(3) {
          width: 120px;
          text-align: center;
          white-space: nowrap;
          > div {
            justify-content: center;
          }
        }
      }
    }
    thead {
      position: sticky;
      top: 0px;
      tr {
        th {
          &:first-child {
            padding-left: 35px;
          }
          font-weight: normal;
          padding: 0 20px;
          font-size: 16px;
          // line-height: 14px;
          color: #ade5d9;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 18px;
          line-height: 22px;
          padding: 0;
          &:first-child {
            > div {
              padding-left: 35px;
            }
          }
          > div {
            background: rgba(0, 255, 255, 0.35);
            min-height: 50px;
            max-height: 65px;
            height: calc((100vh - 595px) / 7);
            padding: 10px 20px;
            margin: 3px 0;
            display: flex;
            align-items: center;
          }
          span {
            overflow: hidden;
            white-space: nowrap;
            max-width: 320px;
            display: block;
            text-overflow: ellipsis;
          }
          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            margin-bottom: -2px;
          }
        }
      }
    }
  }
}

.goal-head {
  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__col {
    margin-right: 50px;
  }
  &__tooltip {
    margin-bottom: -8px;
  }
  &__group {
    margin-bottom: 30px;
    padding-right: 30px;
    &-name {
      font-size: 18px;
      color: #ade5d9;
    }
    &-title {
      font-size: 40px;
      line-height: 44px;
      height: 88px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__title {
    font-size: 18px;
    color: #ade5d9;
    display: flex;
    align-items: center;
  }
  &__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    color: #50ffeb;
    text-transform: uppercase;
  }
  &__text {
    font-size: 18px;
    color: #ade5d9;
    margin-bottom: 2px;
  }
}

.goal-foot {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-size: 32px;
    line-height: 1;
    margin-top: -7px;
    &.big {
      font-size: 40px;
    }
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    margin-left: 40px;
    background: rgba(0, 255, 254, 0.15);
    height: 64px;
    width: 230px;
    text-align: center;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='64' viewBox='0 0 22 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2H2v60h20' stroke='%2300DFDF' stroke-width='4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
      content: "";
    }
    span {
      display: block;
      font-size: 18px;
      color: #50ffeb;
    }
    strong {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 32px;
      line-height: 1;
      justify-content: center;
      img {
        margin-right: 8px;
        margin-bottom: -4px;
      }
    }
  }
}
</style>
